<template>
  <div
    class="overview"
  >
    <data-table
      ref="table"
      :actions="actions"
    />

    <time-modal
      :time-shift="timeShift"
      :clients="clients"
      :persons="persons"
      :projects="projects"
      :services="services"
      :time-types="timeTypes"
      :time-entries="timeEntries"
      :week="week"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from '@/utils/dates'
import mixin from '../components/mixin'

const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Overview',
  components: {
    TimeModal: () => import('src/internal/components/TimeModal'),
  },
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'internal/openTimeEntryFormUpdate',
          name: 'Edit',
        },
        {
          event: 'internal/openTimeEntryFormDelete',
          name: 'Delete',
        },
      ],
    }
  },
  computed: {
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    ...mapToolsGetters([
      'currentViewId',
      'storedItems',
    ]),
    timeShift() {
      return this.$store.state.internal?.timeShift || 0
    },
    clients() {
      const allOrgs = this.storedItems?.organizations?.all || []
      return allOrgs.filter((org) => org.isTimesheetEnabled)
    },
    organizations() {
      return this.storedItems?.organizations?.all || []
    },
    persons() {
      return this.storedItems?.persons?.all || []
    },
    projects() {
      return this.storedItems?.projects?.all || []
    },
    services() {
      return this.storedItems?.services?.all || []
    },
    timeTypes() {
      return this.storedItems?.timeTypes?.all || []
    },
    timeEntries() {
      if (!this.currentViewId) return []
      return (this.storedItems?.overview && this.storedItems?.overview['*']['*']) || []
    },
    week() {
      return dates.getWeek(this.timeShift)
    },
  },
}
</script>
